// footer

footer {
  padding-top: 25px;
  .nav-footer {
    ul {
      border-top: 1px solid $blue;
      overflow: hidden;
      list-style: none;
      margin-left: 270px;
      padding: 5px 0 20px 10px;
      li {
        float: left;
        margin-right: 15px;
        a {
          color: $blue-light;
          text-decoration: none;
          text-transform: uppercase;
          @include font-size(14);
        }
      }
      @media only screen and (max-width: 950px) {
        margin-left: 180px;
      }
      @media only screen and (max-width: $size-M) {
        margin-left: 0;
        padding: 5px 0 20px 30px;
      }
    }
  }
}