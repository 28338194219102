// nav mobile

.nav-mobile {
  display: none;
  ul {
    margin: 0 15px 0 96px;
    padding: 10px 0 10px 0;
    background: #b5dcef;
    list-style: none;
    li {
      border-bottom: 1px solid $blue;
      a {
        display: block;
        padding: 8px 20px;
        color: $blue;
        text-decoration: none;
        text-transform: uppercase;
        &:hover,
        &.active {
          background: $blue-light;
          color: $white;
        }
      }
      span {
        color: $white;
        display: block;
        float: right;
        @include font-size(28);
        padding: 0 15px;
        cursor: pointer;
      }
      ul {
        display: none;
        margin-left: 20px;
        li {
          border: none;
          a {
            padding: 4px 20px;
            text-transform: none;
            &:hover,
            &:active {
              background: transparent;
              color: $white;
            }
          }
        }
      }
    }
  }

  // special inside devisions
  .overview {
    margin: 0 15px 0 96px;
    padding: 25px 20px 4px 20px;
    background: #b5dcef;
    a {
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .current-discipline {
    margin: 0 15px 0 96px;
    padding: 4px 20px;
    font-weight: bold;
    background: #b5dcef;
    text-transform: uppercase;
  }
}