//
// Import Base CSS and add custom
//

// Base Settings
@import "base/settings";
@import "base/colors";

// Mixins
@import "base/mixins";

// Normalize
@import "base/normalize";

// Add global CSS
@import "base/global";

// Add Magnific Popup
@import "lib/magnific_popup/main";


//
// CUSTOM CSS
//

// general
body {
  font-family: $baseFont;
  @include font-size(14);
  background: $white;
  color: $grey;
}

a {
  text-decoration: none;
  color: $blue;
}


.page {
  max-width: $maxPageWidth;
  margin: auto;
}

h1 {
  color: $blue;
  @include font-size(24);
  margin-bottom: 15px;

}

.content-main .default {
  h2:first-child {
    @include font-size(18);
    margin: 0;
  }
}

h3 {
  @include font-size(16);
  /*font-weight: normal;*/
}

h4 {
  @include font-size(14);
  margin: 0.6em 0 0 0;
}

.intro {
  color: $blue-light;
}

.text-blue {
  color: $blue;
}


// header
@import "header";

// nav disciplines
@import "navDisciplines";


// nav mobile
@import "navMobile";


main {
  overflow: hidden;
}

// nav main
@import "navMain";

section.content-main {
  padding-top: 20px;
  margin-left: 270px;
  margin-right: 200px;
  max-width: 630px;
  padding-right: 30px;
  @media only screen and (max-width: 950px) {
    margin-left: 180px;
  }
  @media only screen and (max-width: $size-M) {
    margin-left: 0;
    padding-left: 30px;
    margin-right: 0;
    max-width: none;
  }
}

@import "disciplines_overview";

// siderbar
@import "sidebar";

// news
@import "news";

// footer
@import "footer";

// mail form
@import "mailform";