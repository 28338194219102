// news

.news-list-view {
  margin-top: 20px;
  .article {
    overflow: hidden;
    border-top: 1px solid $grey;
    padding-bottom: 15px;
    h3 {
      margin: 15px 0 8px 0;
      a {
        color: $blue;
        text-decoration: none;
      }
    }
    .news-img-wrap {
      float: left;
      margin-right: 15px;
    }
    .teaser-text p {
      padding-top: 0;
      margin-bottom: 1em;
    }
    .more {
      color: $blue;
      text-decoration: none;
    }
    .footer {
      @include font-size(12);
      padding-bottom: 5px;
      .news-list-date {
      }
    }
  }
}

.news-single {

  .article {

    .footer {
      margin-bottom: 15px;
    }

    .teaser-text {
      color: $blue !important;
      margin-bottom: 1em;
    }
    .news-img-wrap {
      float: right;
      margin-left: 15px;
    }
    .news-backlink-wrap {
      padding: 15px 0 0 0;
      a {
        color: $blue;
        text-decoration: none;
      }
    }
    .facebook {
      padding-top: 15px;
      .share {
        display: none;
      }
      .twitter {
        padding-top: 10px;
      }
    }
    .news-img-caption {
      @include font-size(12);
    }
    .footer {
      @include font-size(12);
    }
  }
}