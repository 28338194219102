//
// Defaul Project Settings
//

$maxPageWidth: 1100px;
$baseFont: Arial,sans-serif;

// breakpoint
$size-XXL: 1100px;
$size-XL: 1000px;
$size-L: 850px;
$size-M: 767px;
$size-S: 420px;