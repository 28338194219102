// Nav Disciplines

.nav-disciplines {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 30px;
  z-index: 9;
  ul {
    overflow: hidden;
    list-style: none;
    margin: 0 0 0 -2px;
    padding: 0;
    float: right;
    li {
      float: left;
      margin: 0 0 2px 2px;
      a {
        display: block;
        width: 41px;
        height: 41px;
        background: url("../Icons/discipline_icon_set.png") no-repeat $white;
        @include hide-text();
      }
      &.nav-item-10 a {
        background-position: -43px 0;
      }
      &.nav-item-11 a {
        background-position: -86px 0;
      }
      &.nav-item-12 a {
        background-position: -129px 0;
      }
      &.nav-item-13 a {
        background-position: -172px 0;
      }
      &.nav-item-14 a {
        background-position: -215px 0;
      }
      &.nav-item-15 a {
        background-position: -258px 0;
      }
      &.nav-item-16 a {
        background-position: -301px 0;
      }
      &.nav-item-17 a {
        background-position: -344px 0;
      }
      &.nav-item-18 a {
        background-position: -387px 0;
      }
      &.nav-item-19 a {
        background-position: -430px 0;
      }
      &.nav-item-20 a {
        background-position: -473px 0;
      }
      &.nav-item-21 a {
        background-position: -516px 0;
      }
      &.nav-item-22 a {
        background-position: -559px 0;
      }
    }
  }
  @media only screen and (max-width: $size-XXL) {
    bottom: 7px;
  }
  @media only screen and (max-width: $size-XL) {
    bottom: 3px;
  }
  @media only screen and (max-width: $size-L) {
    bottom: 1px;
  }
  @media only screen and (max-width: $size-M) {
    position: relative;
    float: none;
    padding-right: 15px;
    margin-top: 64px;
    margin-left: 88px;
  }
  @media only screen and (max-width: $size-S) {
    margin-left: 12px;
  }
}