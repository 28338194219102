//
// Disciplines Overview CSS
//

#c119 {

  .ce-gallery {
    overflow: hidden;
    margin-left: -15px;

    .ce-row {
      float: left;
      margin: 0 0 10px 15px;

      .image-caption {
        @include font-size(10);
      }
    }
  }

}