// nav main

.nav-main {
  width: 270px;
  float: left;
  @include font-size(16);
  ul {
    list-style: none;
    li {
      a {
        color: $grey;
        text-decoration: none;
        display: block;
        padding: 4px 0;
        &:hover,
        &.active {
          font-weight: bold;
        }
      }
      ul {
        padding-left: 15px;
        li {
          a {
            padding: 2px;
          }
        }
      }
    }
    &.inside-discipline {
      margin-top: 0;
    }
  }

  // special inside devisions
  .overview {
    padding: 16px 0 4px 40px;
    a {
      font-weight: bold;
    }
  }
  .current-discipline {
    padding: 4px 0 4px 40px;
    font-weight: bold;
  }

  @media only screen and (max-width: 950px) {
    width: 180px;

    ul {
      padding-left: 15px;
    }
  }
  @media only screen and (max-width: $size-M) {
    display: none;
  }
}