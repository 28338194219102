// Mail Form

div.csc-mailform {

  ol {
    list-style: none;
    li {
      margin-bottom: 10px;
      label {
        width: 100px;
        margin-right: 10px;
        em {
          display: inline;
          padding-left: 5px;
          color: #000000;
        }
      }
      &.csc-form-element-submit {
        padding-left: 110px;
        input {
          cursor: pointer;
          background: $blue;
          color: $white;
          border: none;
          padding: 4px 15px;
        }
      }
      &.csc-form-element-textline {
        input {
          border: 1px solid #cccccc;
          width: 300px;
        }
      }
      &.csc-form-element-textarea {
        textarea {
          border: 1px solid #cccccc;
          width: 300px;
        }
      }
    }
  }

}