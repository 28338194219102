// header

.page-header {
  position: relative;
  overflow: hidden;
  .nav-mobile-icon {
    display: none;
    position: absolute;
    right: 15px;
    top: 16px;
    width: 40px;
    padding-top: 6px;
    cursor: pointer;
    z-index: 9;
    @media only screen and (max-width: $size-M) {
      display: block;
    }
    div {
      height: 4px;
      background: $white;
      margin-bottom: 6px;
    }
  }

  .header-image {
    .ce-hero-slider-slides {
      position: absolute;
      width: 100%;
      figure {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        &.next {
          z-index: 2;
        }
      }
    }
    img {
      display: block;
    }
  }

  .header-mask {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    img {
      display: block;
    }
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0 0 2.727272727272727%;
    width: 37.09090909090909%;
    z-index: 9;

    @media only screen and (max-width: $size-M) {
      width: 275px;
    }

    img {
      vertical-align: top;
      &:first-child {
        padding-right: 3.676470588235294%; // 15px
        width: 25.49019607843137%;

        @media only screen and (max-width: $size-M) {
          width: 70px;
          padding-right: 10px;
        }

      }
      &:last-child {
        padding-top: 10px;
        width: 69.85294117647059%;
      }
    }
  }
  .blue-bar {
    height: 26px;
    background: $blue;
    position: relative;
    z-index: 8;
  }

  @media only screen and (max-width: $size-M) {
    padding-bottom: 15px;
    background: $blue;
    .header-image,
    .header-mask,
    .blue-bar {
      display: none;
    }
  }

  @media only screen and (max-width: $size-S) {
    .logo img:first-child {
      width: 46px;
    }

  }
}